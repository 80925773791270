import client from "./client";

const getBatches = (language, level = null, limit) => {
  let url = `/v1/batches`;
  if (language) {
    url = `${url}?language=${language}`;
  }
  if (level) {
    url = `${url}&level=${level}`;
  }

  if (limit) {
    url = `${url}&limit=${limit}`;
  }

  url = `${url}&sort=-batchDate`;
  return client.get(url);
};

const addRating = (ratingInfo) => client.post("/v1/ratings", ratingInfo);

const addSpotRating = (ratingInfo) =>
  client.post("/v1/spot-ratings", ratingInfo);

const getRatingsWithDate = ({ fromDate, toDate }, language, level = null) => {
  if (level) {
    return client.get(
      `/v1/ratings/agg-rating/date?fromDate=${fromDate}&toDate=${toDate}&language=${language}&level=${level}`,
    );
  }
  return client.get(
    `/v1/ratings/agg-rating/date?fromDate=${fromDate}&toDate=${toDate}&language=${language}`,
  );
};

const getSpotRatingsWithDate = (
  { fromDate, toDate },
  language,
  level = null,
) => {
  if (level) {
    return client.get(
      `/v1/spot-ratings/agg-rating/date?fromDate=${fromDate}&toDate=${toDate}&language=${language}&level=${level}`,
    );
  }
  return client.get(
    `/v1/spot-ratings/agg-rating/date?fromDate=${fromDate}&toDate=${toDate}&language=${language}`,
  );
};

const getTrainerRatings = (trainerId) =>
  client.get(`/v1/trainers/${trainerId}/ratings`);

export default {
  getBatches,
  addRating,
  addSpotRating,
  getSpotRatingsWithDate,
  getRatingsWithDate,
  getTrainerRatings,
};

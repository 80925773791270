import useApi from "./useApi";
import admin from "../api/admin";
import AuthContext from "../context/AuthContext";
import { useContext } from "react";

const useAuth = () => {
  const getCurrentUserApi = useApi(admin.getMe);
  const { user, setUser } = useContext(AuthContext);

  // const login = () => {};
  // const logIn = (authToken) => {
  //   const user = jwtDecode(authToken);
  //   setUser(user);
  //   authStorage.storeToken(authToken);
  // };

  const loadUser = async (authToken) => {
    localStorage.setItem("token", authToken);
    const request = await getCurrentUserApi.request();
    if (!request.ok) return;
    setUser(request.data.data);
  };

  const logOut = () => {
    setUser(null);
    localStorage.removeItem("token");
  };

  return { user, loadUser, logOut };
};

export default useAuth;

import {
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import rating from "../../api/rating";
import PublicLayout from "../../components/layouts/PublicLayout";
import useApi from "../../hooks/useApi";
import RatingDescModal from "./RatingDescModal";

const TrainerRatings = () => {
  const { trainerId } = useParams();
  const trainerRatingApi = useApi(rating.getTrainerRatings);
  const [error, setError] = useState();
  const [trainer, setTrainer] = useState(null);
  const [ratings, setRatings] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [currentModalInfo, setCurrentModalInfo] = useState();

  useEffect(() => {
    fetchRatingsForTrainer(trainerId);
  }, [trainerId]);

  const fetchRatingsForTrainer = async (trainerId) => {
    const result = await trainerRatingApi.request(trainerId);
    if (!result.ok) {
      if (result.data) setError(result.data.error);
      else {
        setError("An unexpected error occurred.");
        console.log(result);
      }
      return;
    }
    setError(false);
    setTrainer(result.data.data);
    setRatings(result.data.data.spotRatings);
  };

  const handleView = (data) => {
    setIsOpen(true);
    setCurrentModalInfo(data);
  };

  const handleViewClose = () => {
    setIsOpen(false);
    setCurrentModalInfo(null);
  };

  const columns = [
    {
      field: "id",
      header: "ID",
      hide: true,
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 150,
      valueFormatter: ({ row }) => moment(row.createdAt).format("DD MMM YYYY"),
    },
    { field: "rating", headerName: "Rating", width: 150 },
    { field: "remarks", headerName: "Remarks", width: 300 },
    {
      field: "action",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <>
          <Button
            variant="text"
            color="primary"
            size="small"
            onClick={() => handleView(params.row)}
          >
            Info
          </Button>
        </>
      ),
    },
  ];

  return (
    <PublicLayout>
      <Container spacing={2}>
        <Grid item padding={3} style={{ margin: "25px auto" }}>
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            {trainer?.name}
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="textSecondary"
            component="p"
          >
            Average Rating : {trainer?.averageRating}
          </Typography>
        </Grid>

        <Grid item padding={3}>
          <Card xs={12} style={{ marginTop: "10px" }}>
            <div
              style={{
                height: 400,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {trainerRatingApi.loading && <CircularProgress />}
              {trainerRatingApi.error && (
                <Alert severity="error">{error}</Alert>
              )}
              {!trainerRatingApi.loading && ratings.length > 0 ? (
                <>
                  <DataGrid
                    rows={ratings}
                    columns={columns}
                    getRowId={(row) => row._id}
                    pageSize={5}
                  />
                </>
              ) : (
                <Typography variant="h4">No data found</Typography>
              )}
            </div>
          </Card>
        </Grid>
      </Container>

      <RatingDescModal
        open={isOpen}
        handleClose={handleViewClose}
        data={currentModalInfo}
      />
    </PublicLayout>
  );
};

export default TrainerRatings;

import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router";
import { Menu, MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    minHeight: "60vh",
  },
  avatar: {
    // margin: theme.spacing(1),
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

export default function Landing() {
  const classes = useStyles();
  const history = useHistory();

  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [anchorEl4, setAnchorEl4] = useState(null);

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClick4 = (event) => {
    setAnchorEl4(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };
  const handleClose4 = () => {
    setAnchorEl4(null);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar} src="/logo.png"></Avatar> */}
        <img src="/logo.png" width="250px" />
        <Typography component="h1" variant="h3" align="center">
          English Partner Feedback Portal
        </Typography>
        <Typography component="h1" variant="h5" align="center">
          Please get the link from your trainer and rate them!
        </Typography>
        {/* <form
          className={classes.form}
          noValidate
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick1}
            >
              Tamil
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl1}
              keepMounted
              open={Boolean(anchorEl1)}
              onClose={handleClose1}
            >
              <MenuItem onClick={() => history.push("/tamil?level=Basic")}>
                Basic
              </MenuItem>
              <MenuItem
                onClick={() => history.push("/tamil?level=Intermediate")}
              >
                Intermediate
              </MenuItem>
              <MenuItem
                onClick={() => history.push("/tamil?level=SpeakingRoom")}
              >
                Speaking Room
              </MenuItem>
            </Menu>
          </div>
          <div>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick2}
            >
              Kannada
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl2}
              keepMounted
              open={Boolean(anchorEl2)}
              onClose={handleClose2}
            >
              <MenuItem onClick={() => history.push("/kannada?level=Basic")}>
                Basic
              </MenuItem>
              <MenuItem
                onClick={() => history.push("/kannada?level=Intermediate")}
              >
                Intermediate
              </MenuItem>
              <MenuItem
                onClick={() => history.push("/kannada?level=SpeakingRoom")}
              >
                Speaking Room
              </MenuItem>
            </Menu>
          </div>
          <div>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick3}
            >
              Hindi
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl3}
              keepMounted
              open={Boolean(anchorEl3)}
              onClose={handleClose3}
            >
              <MenuItem onClick={() => history.push("/hindi?level=Basic")}>
                Basic
              </MenuItem>
              <MenuItem
                onClick={() => history.push("/hindi?level=Intermediate")}
              >
                Intermediate
              </MenuItem>
              <MenuItem
                onClick={() => history.push("/hindi?level=SpeakingRoom")}
              >
                Speaking Room
              </MenuItem>
            </Menu>
          </div>

          <div>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick4}
            >
              Telugu
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl4}
              keepMounted
              open={Boolean(anchorEl4)}
              onClose={handleClose4}
            >
              <MenuItem onClick={() => history.push("/telugu?level=Basic")}>
                Basic
              </MenuItem>
              <MenuItem
                onClick={() => history.push("/telugu?level=Intermediate")}
              >
                Intermediate
              </MenuItem>
              <MenuItem
                onClick={() => history.push("/telugu?level=SpeakingRoom")}
              >
                Speaking Room
              </MenuItem>
            </Menu>
          </div>
        </form> */}
      </div>
    </Container>
  );
}

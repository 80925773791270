import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React from "react";

const BatchInfoModel = ({ open, handleClose, data }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Batch Info"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid>
            <Grid item>
              <Typography variant="h6">Language</Typography>
              <Typography variant="body1">
                {data?.language.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6">Level</Typography>
              <Typography variant="body1">
                {data?.level.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6">Batch Date</Typography>
              <Typography variant="body1">
                {moment(data?.batchDate).format("DD MMM YYYY")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6">Trainer Pairs</Typography>
              <ul>
                {data?.group.map((pair) => (
                  <Typography variant="body1">
                    {pair?.trainer1?.name} - {pair?.trainer2?.name}
                  </Typography>
                ))}
              </ul>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BatchInfoModel;

import {
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import React from "react";

const GroupSelector = ({ group, onChange, value }) => {
  return (
    <Card xs={12}>
      <CardContent xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Choose trainer group
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            onChange={(e) => onChange(e.target.value)}
          >
            <MenuItem value="">Select your trainers</MenuItem>
            {group.map((pair) => (
              <MenuItem value={pair}>
                {pair.trainer1.name} - {pair.trainer2.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default GroupSelector;

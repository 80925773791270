import { create } from "apisauce";

const apiClient = create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api`,
});

apiClient.addAsyncRequestTransform(async (request) => {
  const authToken = localStorage.getItem("token");
  if (!authToken) return;
  request.headers["authorization"] = `Bearer ${authToken}`;
});

apiClient.addAsyncRequestTransform(async (request) => {
  request.headers["Content-Type"] = "application/json";
});

// const get = apiClient.get;
// apiClient.get = async (url, params, axiosConfig) => {
//   const response = await get(url, params, axiosConfig);

//   if (response.ok) {
//     cache.store(url, response.data);
//     return response;
//   }

//   const data = await cache.get(url);
//   return data ? { ok: true, data } : response;
// };

export default apiClient;

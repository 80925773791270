import React, { useState } from "react";
import PrivateLayout from "../../components/layouts/PrivateLayout";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { CircularProgress, Select } from "@material-ui/core";
import useApi from "../../hooks/useApi";
import admin from "../../api/admin";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const formState = {
  name: "",

  phone: "",
  level: "",
  language: "",
};

const AddTrainer = () => {
  const classes = useStyles();
  const createTrainerApi = useApi(admin.createTrainer);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formData, setFormData] = useState(formState);

  const { name, phone, language, level } = formData;

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    const result = await createTrainerApi.request(formData);
    if (!result.ok) {
      if (result.data) setError(result.data.error);
      else {
        setError("An unexpected error occurred.");
        console.log(result);
      }
      return;
    }
    setSuccess(true);
    setFormData(formState);
    setTimeout(() => {
      setSuccess(false);
    }, 5000);
  };

  return (
    <PrivateLayout>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Add New Trainer
          </Typography>
          {error && (
            <Alert severity="error" variant="filled">
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" variant="filled">
              Trainer has been added successfully...
            </Alert>
          )}

          {createTrainerApi.loading ? (
            <CircularProgress size={60} />
          ) : (
            <form className={classes.form} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="name"
                    name="name"
                    value={name}
                    onChange={handleChange}
                    variant="outlined"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    autoFocus
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="phone"
                    name="phone"
                    value={phone}
                    onChange={handleChange}
                    variant="outlined"
                    required
                    fullWidth
                    id="phone"
                    label="Phone"
                    type="tel"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Select
                    variant="outlined"
                    native
                    name="level"
                    value={level}
                    onChange={handleChange}
                    fullWidth
                  >
                    <option value="">Select level</option>
                    <option value="Basic">Basic</option>
                    <option value="Intermediate">Intermediate</option>
                    <option value="Advanced">Advanced</option>
                    <option value="SpeakingRoom">Speaking Room</option>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Select
                    variant="outlined"
                    native
                    name="language"
                    value={language}
                    onChange={handleChange}
                    fullWidth
                  >
                    <option value="">Select language</option>
                    <option value="tamil">Tamil</option>
                    <option value="hindi">Hindi</option>
                    <option value="kannada">Kannada</option>
                    <option value="telugu">Telugu</option>
                    <option value="urudu"> Urudu</option>
                    <option value="malayalam"> Malayalam</option>
                    <option value="bengali"> Bengali</option>
                    <option value="multilingual"> Multilingual</option>
                  </Select>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Add
              </Button>
            </form>
          )}
        </div>
      </Container>
    </PrivateLayout>
  );
};

export default AddTrainer;

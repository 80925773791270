import { Button, Card, Container, Grid } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useState, useEffect } from "react";
import PrivateLayout from "../../components/layouts/PrivateLayout";

import adminApi from "../../api/admin";
import useApi from "../../hooks/useApi";

const TrainersList = () => {
  const getTrainersApi = useApi(adminApi.getTrainers);
  const deleteTrainerApi = useApi(adminApi.deleteTrainer);

  const [trainers, setTrainers] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    loadTrainers();
  }, []);

  const loadTrainers = async () => {
    const result = await getTrainersApi.request();

    if (!result.ok) {
      if (result.data) setError(result.data.error);
      else {
        setError("An unexpected error occurred.");
        console.log(result);
      }
      return;
    }

    setTrainers(result.data.data);
    setError(false);
  };

  const columns = [
    { field: "_id", headerName: "Identifier", width: 250 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "level", headerName: "Level", width: 150 },
    { field: "phone", headerName: "Phone", width: 150 },
    { field: "language", headerName: "Language", width: 150 },
    {
      field: "id",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          {/* <Button
            variant="text"
            color="primary"
            size="small"
            onClick={() => handleEdit(params.id)}
          >
            Edit
          </Button> */}
          <Button
            variant="text"
            color="primary"
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(
                `https://feedbacks.englishpartner.in/ratings/${params.id}`,
              );
            }}
          >
            Copy Link
          </Button>
          <Button
            variant="text"
            color="secondary"
            size="small"
            onClick={() => handleDelete(params.id)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  const handleEdit = (id) => {
    console.log(id);
  };

  const handleDelete = async (id) => {
    if (
      !window.confirm(
        "Are you sure you want to delete this trainer? All records of this trainer will be lost",
      )
    )
      return;
    const result = await deleteTrainerApi.request(id);

    if (!result.ok) {
      if (result.data) setError(result.data.error);
      else {
        setError("An unexpected error occurred.");
        console.log(result);
      }
      return;
    }
    loadTrainers();
    setError(false);
  };

  return (
    <PrivateLayout>
      <Container style={{ marginTop: "20px" }}>
        <Grid item padding={3}>
          <Card xs={12}>
            <div style={{ height: 550, width: "100%" }}>
              <DataGrid rows={trainers} columns={columns} pageSize={20} />
            </div>
          </Card>
        </Grid>
      </Container>
    </PrivateLayout>
  );
};

export default TrainersList;

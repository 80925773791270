import {
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import React from "react";

import moment from "moment";
const BatchSelector = ({ batches, value, onChange }) => {
  return (
    <Card xs={12}>
      <CardContent xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Choose your batch
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            required
          >
            <MenuItem value="">
              <em>Choose Your Batch Date</em>
            </MenuItem>
            {batches.map((batch) => (
              <MenuItem key={batch.id} value={batch}>
                {moment(batch.batchDate).format("DD MMM YYYY")}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default BatchSelector;

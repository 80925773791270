import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import admin from "./api/admin";
import AuthContext from "./context/AuthContext";
import AddBatch from "./pages/admin/AddBatch";
import AddTrainer from "./pages/admin/AddTrainer";
import TrainersList from "./pages/admin/TrainersList";
import Login from "./pages/auth/Login";
import AddRatings from "./pages/ratings/AddRatings";
import QueryRatings from "./pages/ratings/QueryRatings";

import useApi from "./hooks/useApi";
import PrivateRoute from "./routes/PrivateRoute";
import TrainerRatings from "./pages/ratings/TrainerRatings";
import BatchesList from "./pages/admin/BatchesList";
import Landing from "./pages/ratings/Landing";
import RatingExpired from "./pages/misc/RatingExpired";
import AddSpotRatings from "./pages/ratings/AddSpotRatings";

const App = () => {
  const getUserViaTokenApi = useApi(admin.getMe);
  const [user, setUser] = useState();

  const restoreUser = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const request = await getUserViaTokenApi.request();
      if (!request.ok) return;
      setUser(request.data.data);
    }
  };

  useEffect(() => {
    if (!getUserViaTokenApi.loading && user?.email) {
      <Redirect to="/admin/trainers" />;
    }
  }, [user]);

  useEffect(() => {
    restoreUser();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <Router>
        <Switch>
          <Route path="/login" component={Login} exact />
          <Route path="/trainers/ratings" component={QueryRatings} exact />
          <Route
            path="/trainers/ratings/:trainerId"
            component={TrainerRatings}
            exact
          />
          {/* <Route path="/rate" component={AddRatings} exact /> */}
          <PrivateRoute path="/admin/trainers" component={TrainersList} exact />
          <PrivateRoute
            path="/admin/trainers/add"
            component={AddTrainer}
            exact
          />
          <PrivateRoute path="/admin/batches/add" component={AddBatch} exact />
          <PrivateRoute path="/admin/batches" component={BatchesList} exact />
          <Route path="/:language" component={AddRatings} exact />
          <Route path="/ratings/:trainerId" component={AddSpotRatings} exact />
          <Route path="/feedback/expired" component={RatingExpired} exact />
          <Route path="/" component={Landing} exact />
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;

import client from "./client";

const createBatch = (batchInfo) => client.post("/v1/batches", batchInfo);
const deleteBatch = (batchId) => client.delete(`/v1/batches/${batchId}`);
const getBatches = () => client.get(`/v1/batches`);
const createTrainer = (trainerInfo) => client.post("/v1/trainers", trainerInfo);
const deleteTrainer = (trainerId) => client.delete(`/v1/trainers/${trainerId}`);
const getTrainerInfo = (trainerId) => client.get(`/v1/trainers/${trainerId}`);

const getTrainers = (language, level = null) => {
  let url = `/v1/trainers`;
  if (language) {
    url = `${url}?language=${language}`;
  }
  if (level) {
    url = `${url}&level=${level}`;
  }

  return client.get(url);
};

const login = (loginInfo) => client.post("/v1/auth/admin/login", loginInfo);

const getMe = () => client.get("/v1/auth/me");

export default {
  createBatch,
  deleteBatch,
  getBatches,
  getTrainers,
  deleteTrainer,
  createTrainer,
  getTrainerInfo,
  login,
  getMe,
};

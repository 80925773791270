import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import PublicLayout from "../../components/layouts/PublicLayout";
import BatchSelector from "../../components/common/BatchSelector";
import GroupSelector from "../../components/common/GroupSelector";
import { Alert, Rating } from "@material-ui/lab";
import {
  Button,
  Card,
  CardContent,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import rating from "../../api/rating";
import useApi from "../../hooks/useApi";
import { Redirect, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },

  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function AddRatings() {
  const { language } = useParams();
  let query = useQuery();
  let level = query.get("level");

  const classes = useStyles();

  const getBatches = useApi(rating.getBatches);
  const addRatingApi = useApi(rating.addRating);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [batches, setBatches] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState("");
  const [selectedPair, setSelectedPair] = useState("");

  const [remarks1, setRemarks1] = useState("");
  const [remarks2, setRemarks2] = useState("");

  const [success, setSuccess] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    loadBatches();
  }, []);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 5000);
    }
  }, [error]);

  const loadBatches = async () => {
    const result = await getBatches.request(language, level, 9);
    if (!result.ok) {
      if (result.data) setError(result.data.error);
      else {
        setError("An unexpected error occurred.");
        console.log(result);
      }
      return;
    }
    setBatches(result.data.data);
  };

  const onBatchChange = (e) => {
    setSelectedBatch(e);
    setSelectedPair(null);
  };

  const validatePhone = (enteredPhoneNumber) => {
    var pattern = new RegExp(/^[0-9\b]+$/);
    if (!pattern.test(enteredPhoneNumber)) {
      setError("Enter valid phone number");
    } else if (enteredPhoneNumber.length !== 10) {
      setError("Enter valid phone number");
    } else {
      return true;
    }
    return false;
  };

  const validateRatingInputs = () => {
    const phoneCheck = validatePhone(phone);
    if (phoneCheck) return true;
    return false;
  };

  const [rate1, setRate1] = useState(null);
  const [rate2, setRate2] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess(false);
    setError(false);

    // if (!validateRatingInputs()) {
    //   return;
    // }

    if (rate1 === null || rate2 === null) {
      return setError("Please fill the ratings for trainers.");
    }

    let formData = {};
    formData.language = language;
    formData.name = name;
    formData.phone = phone;
    formData.batch = selectedBatch._id;
    formData.trainer1 = selectedPair.trainer1._id;
    formData.trainer2 = selectedPair.trainer2._id;
    formData.rate1 = rate1;
    formData.rate2 = rate2;
    formData.remarks1 = remarks1;
    formData.remarks2 = remarks2;

    const result = await addRatingApi.request(formData);

    if (!result.ok) {
      if (result.data) setError(result.data.error);
      else {
        setError("An unexpected error occurred.");
        console.log(result);
      }
      return;
    }

    if (result.data.success) setSuccess(true);
  };

  if (
    language !== "tamil" &&
    language !== "kannada" &&
    language !== "hindi" &&
    language !== "telugu"
  ) {
    return <Redirect to="/" />;
  }

  if (moment().date() === 30 || moment().date() === 31) {
    return <Redirect to="/feedback/expired" />;
  }

  return (
    <>
      <PublicLayout>
        {success ? (
          <Container
            maxWidth="sm"
            component="main"
            className={classes.heroContent}
          >
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Thank you for your feedback!
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              component="p"
            >
              Your feedback has been received.
            </Typography>
          </Container>
        ) : (
          <>
            <Container
              maxWidth="sm"
              component="main"
              className={classes.heroContent}
            >
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="textPrimary"
                gutterBottom
              >
                Your feedback is important to us!
              </Typography>
              <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                component="p"
              >
                Please fill the below details.
              </Typography>
            </Container>
            {/* End hero unit */}
            <form onSubmit={handleSubmit}>
              <Container maxWidth="sm">
                {error && <Alert severity="info">{error}</Alert>}
                <Grid
                  container
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                  spacing={3}
                >
                  <Grid item xs={12} sm={6}>
                    <Card>
                      <CardContent align="center" justify="center">
                        <TextField
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          id="Name"
                          label="Your name"
                          fullWidth
                          required
                          pattern="^[a-zA-Z]+(\s[a-zA-Z]+)?$"
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Card>
                      <CardContent align="center" justify="center">
                        <TextField
                          type="tel"
                          name="phone"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          id="phone"
                          label="Your mobile number"
                          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          fullWidth
                          min={10}
                          max={10}
                          required
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: "10px" }}>
                  <Grid item xs={12}>
                    <BatchSelector
                      batches={batches}
                      onChange={onBatchChange}
                      value={selectedBatch}
                      required
                    />
                  </Grid>
                </Grid>

                {selectedBatch && (
                  <Grid container style={{ marginBottom: "10px" }}>
                    <Grid item xs={12}>
                      <GroupSelector
                        group={selectedBatch.group}
                        value={selectedPair}
                        onChange={setSelectedPair}
                      />
                    </Grid>
                  </Grid>
                )}

                {selectedPair && (
                  <>
                    <Grid
                      container
                      style={{ marginBottom: "10px" }}
                      spacing={2}
                    >
                      <Grid item xs={12} sm={6}>
                        <Card>
                          <CardContent align="center" justify="center">
                            <Typography variant="h6">
                              {selectedPair.trainer1.name}
                            </Typography>
                            <Rating
                              name="trainer1"
                              value={rate1}
                              onChange={(event, newValue) => setRate1(newValue)}
                              required
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Card>
                          <CardContent align="center" justify="center">
                            <Typography variant="h6">
                              {selectedPair.trainer2.name}
                            </Typography>
                            <Rating
                              name="trainer2"
                              value={rate2}
                              onChange={(event, newValue) => setRate2(newValue)}
                              required
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </>
                )}

                {rate1 && rate2 && (
                  <Grid
                    container
                    style={{ marginBottom: "10px", marginTop: "10px" }}
                    spacing={1}
                  >
                    <Grid item xs={12}>
                      <Card container>
                        <CardContent align="center" justify="center">
                          <TextField
                            name="remarks1"
                            label={`Feedback on ${selectedPair.trainer1.name}`}
                            multiline
                            value={remarks1}
                            onChange={(e) => setRemarks1(e.target.value)}
                            placeholder={`Add your comments on ${selectedPair.trainer1.name}`}
                            fullWidth
                          ></TextField>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card container>
                        <CardContent align="center" justify="center">
                          <TextField
                            name="remarks2"
                            label={`Feedback on ${selectedPair.trainer2.name}`}
                            multiline
                            value={remarks2}
                            onChange={(e) => setRemarks2(e.target.value)}
                            placeholder={`Add your comments on ${selectedPair.trainer2.name}`}
                            fullWidth
                          ></TextField>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                )}

                {addRatingApi.loading ? (
                  <Container
                    align="center"
                    justify="center"
                    style={{ marginTop: "10px" }}
                  >
                    <CircularProgress size={26} />
                  </Container>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    style={{ marginTop: "10px" }}
                  >
                    Submit
                  </Button>
                )}

                {/* {
                  <Container s>
                    <CircularProgress size={26} />
                  </Container>
                }
                <Button fullWidth variant="text" color="primary" type="submit">
                  Submit feedback
                </Button> */}
              </Container>
            </form>
          </>
        )}
      </PublicLayout>
    </>
  );
}

import React, { useState } from "react";
import PrivateLayout from "../../components/layouts/PrivateLayout";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { MenuItem, Select } from "@material-ui/core";

import adminApi from "../../api/admin";
import useApi from "../../hooks/useApi";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const AddBatch = () => {
  const classes = useStyles();

  const createBatchApi = useApi(adminApi.createBatch);
  const getTrainersApi = useApi(adminApi.getTrainers);

  const [error, setError] = useState();
  const [trainers, setTrainers] = useState([]);
  const [batch, setBatch] = useState(null);
  const [language, setLanguage] = useState();
  const [level, setLevel] = useState();
  const [inputList, setInputList] = useState([{ trainer1: "", trainer2: "" }]);

  const setLanguageAndResetFields = async (e) => {
    await setLanguage(e.target.value);
    await setLevel("");
    await setTrainers([]);
  };

  const setLevelAndLoadTrainers = async (e) => {
    await setLevel(e.target.value);
    await loadTrainers(language, e.target.value);
  };

  const loadTrainers = async (language, level) => {
    const result = await getTrainersApi.request(language, level);

    if (!result.ok) {
      if (result.data) setError(result.data.error);
      else {
        setError("An unexpected error occurred.");
        console.log(result);
      }
      return;
    }

    setTrainers(result.data.data);
  };

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { trainer1: "", trainer2: "" }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (!language) return alert("Please select batch's language");
    if (!level) return alert("Please select level");
    if (!batch) return alert("Batch is required");

    const validate = inputList.filter(
      (pair) => pair.trainer1 === "" || pair.trainer2 === "",
    );
    if (validate.length > 0) return alert("Please fill all trainer pairs...");

    let postBody = {
      language: language,
      level: level,
      batchDate: batch,
      group: inputList,
    };

    const result = await createBatchApi.request(postBody);

    if (!result.ok) {
      if (result.data) setError(result.data.error);
      else {
        setError("An unexpected error occurred.");
        console.log(result);
      }
      return;
    }

    alert("Batches has been added successfully");
    setInputList([{ trainer1: "", trainer2: "" }]);
  };

  return (
    <PrivateLayout>
      <Container component="main" maxWidth="md">
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Map Batch Dates With Trainer Groups
          </Typography>
          {error && <Alert severity="error">{error}</Alert>}
          <form onSubmit={handleSubmit} className={classes.form} noValidate>
            <Grid container sx={12} sm={12} spacing={1}>
              <Grid item xs={12} sm={6}>
                <Select
                  variant="outlined"
                  native
                  name="language"
                  value={language}
                  onChange={(e) => setLanguageAndResetFields(e)}
                  fullWidth
                >
                  <option value="">Select language</option>
                  <option value="tamil">Tamil</option>
                  <option value="hindi">Hindi</option>
                  <option value="kannada">Kannada</option>
                  <option value="telugu">Telugu</option>
                </Select>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Select
                  variant="outlined"
                  native
                  name="level"
                  value={level}
                  onChange={(e) => setLevelAndLoadTrainers(e)}
                  fullWidth
                >
                  <option value="">Select level</option>
                  <option value="Basic">Basic</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="SpeakingRoom">Speaking Room</option>
                </Select>
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="batch"
                  name="batch"
                  variant="outlined"
                  required
                  fullWidth
                  id="batch"
                  label="Batch"
                  autoFocus
                  type="date"
                  value={batch}
                  onChange={(e) => setBatch(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              {!getTrainersApi.loading &&
                trainers.length > 0 &&
                inputList.map((x, i) => {
                  return (
                    <Grid container sm={12} key={i}>
                      <Typography variant="body1">Pair {i + 1}</Typography>
                      <Grid container sm={12}>
                        <Grid item xs={12} sm={5}>
                          <Select
                            variant="outlined"
                            fullWidth
                            name="trainer1"
                            value={x.trainer1}
                            onChange={(e) => handleInputChange(e, i)}
                            label="trainer1"
                            id="trainer1"
                          >
                            <MenuItem value="">
                              <em>Choose Trainer</em>
                            </MenuItem>
                            {trainers.map((trainer) => (
                              <MenuItem key={trainer.id} value={trainer.id}>
                                {trainer.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item xs={12} sm={5} className="mt-2">
                          <Select
                            variant="outlined"
                            fullWidth
                            name="trainer2"
                            value={x.trainer2}
                            onChange={(e) => handleInputChange(e, i)}
                            label="trainer2"
                            id="trainer2"
                          >
                            <MenuItem value="">
                              <em>Choose Trainer</em>
                            </MenuItem>
                            {trainers.map((trainer) => (
                              <MenuItem key={trainer.id} value={trainer.id}>
                                {trainer.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: ".5rem",
                            }}
                          >
                            {inputList.length !== 1 && (
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handleRemoveClick(i)}
                              >
                                -
                              </Button>
                            )}
                            {inputList.length - 1 === i && (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddClick}
                              >
                                +
                              </Button>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Add
            </Button>
          </form>
        </div>
      </Container>
    </PrivateLayout>
  );
};

export default AddBatch;

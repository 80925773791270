import {
  Container,
  Grid,
  Card,
  CircularProgress,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import React, { useEffect, useState } from "react";
import admin from "../../api/admin";
import PrivateLayout from "../../components/layouts/PrivateLayout";
import useApi from "../../hooks/useApi";
import BatchInfoModel from "./BatchInfoModel";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
}));

const BatchesList = () => {
  const classes = useStyles();
  const batchesListApi = useApi(admin.getBatches);
  const batchesDeleteApi = useApi(admin.deleteBatch);
  const [batches, setBatches] = useState([]);
  const [error, setError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentModalInfo, setCurrentModalInfo] = useState();

  const columns = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "batchDate",
      headerName: "Batch Date",
      width: 150,
      valueFormatter: ({ row }) => moment(row?.batchDate).format("DD MMM YYYY"),
    },
    {
      field: "language",
      headerName: "Language",
      width: 150,
      valueFormatter: ({ row }) => row.language.toUpperCase(),
    },
    {
      field: "level",
      headerName: "Level",
      width: 150,
      valueFormatter: ({ row }) => row.level.toUpperCase(),
    },
    {
      field: "action",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <Button
            variant="text"
            color="primary"
            size="small"
            onClick={() => handleView(params.row)}
          >
            Info
          </Button>
          <Button
            variant="text"
            color="danger"
            size="small"
            onClick={() => handleDelete(params.row)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    loadBatches();
  }, []);

  const loadBatches = async () => {
    const result = await batchesListApi.request();
    if (!result.ok) {
      if (result.data) setError(result.data.error);
      else {
        setError("An unexpected error occurred.");
        console.log(result);
      }
      return;
    }
    setError(false);
    setBatches(result.data.data);
  };

  const handleView = (data) => {
    setIsOpen(true);
    setCurrentModalInfo(data);
  };

  const handleViewClose = () => {
    setIsOpen(false);
    setCurrentModalInfo(null);
  };

  const handleDelete = async ({ id: batchId }) => {
    if (
      window.confirm(
        "All the ratings will be deleted along with this batch.  Are you sure to proceed?",
      )
    ) {
      const result = await batchesDeleteApi.request(batchId);
      if (!result.ok) {
        if (result.data) setError(result.data.error);
        else {
          setError("An unexpected error occurred.");
          console.log(result);
        }
        return;
      }
      setError(false);
      loadBatches();
    }
  };

  console.log(batchesListApi.data.success);
  return (
    <PrivateLayout>
      <Container style={{ marginTop: "20px" }}>
        <Grid item padding={3} xs={12}>
          {error && <Alert severity="error">{error}</Alert>}
          {batchesDeleteApi?.data?.success && (
            <Alert severity="success">
              Batch has been deleted successfully.
            </Alert>
          )}
          <Card xs={12} style={{ marginTop: "10px" }}>
            <div
              style={{
                height: 400,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {batchesListApi.loading ? (
                <CircularProgress />
              ) : batches.length <= 0 ? (
                <Typography variant="h4">No data found</Typography>
              ) : (
                <DataGrid
                  rows={batches}
                  columns={columns}
                  pageSize={5}
                  checkboxSelection
                />
              )}
            </div>
          </Card>
        </Grid>
      </Container>
      <BatchInfoModel
        open={isOpen}
        handleClose={handleViewClose}
        data={currentModalInfo}
      />
    </PrivateLayout>
  );
};

export default BatchesList;

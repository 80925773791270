import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { DataGrid } from "@material-ui/data-grid";
import PublicLayout from "../../components/layouts/PublicLayout";
import useApi from "../../hooks/useApi";
import rating from "../../api/rating";
import moment from "moment";
import {
  Button,
  CardContent,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useHistory, useLocation } from "react-router";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

export default function QueryRatings() {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const ratingApi = useApi(rating.getSpotRatingsWithDate);
  const [error, setError] = useState();
  const [trainers, setTrainers] = useState([]);
  const [formData, setFormData] = useState({
    fromDate: moment().startOf("month").format("YYYY-MM-DD").toString(),
    toDate: moment().endOf("month").format("YYYY-MM-DD").toString(),
  });

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      valueFormatter: ({ row }) => row?.trainer[0]?._id,
      hide: true,
    },
    {
      field: "trainer",
      headerName: "Name",
      width: 150,
      valueFormatter: ({ row }) => row?.trainer[0]?.name,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
      valueFormatter: ({ row }) => row?.trainer[0]?.phone,
    },
    { field: "queryRating", headerName: "Rating", width: 150 },
    { field: "totalUsersRated", headerName: "Total Users Rated", width: 150 },
    // {
    //   field: "averageRating",
    //   headerName: "Overall Rating",
    //   width: 150,
    //   valueFormatter: ({ row }) => row?.trainer[0]?.averageRating,
    // },
    {
      field: "action",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <Button
            variant="text"
            color="primary"
            size="small"
            onClick={() => handleView(params)}
          >
            View
          </Button>
        </>
      ),
    },
  ];

  const handleView = (params) => {
    history.push(`/trainers/ratings/${params.row.trainer[0]._id}`);
  };

  const params = new URLSearchParams(location.search);
  const language = params.get("language");
  const level = params.get("level");

  const { fromDate, toDate } = formData;
  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    setError(false);
    e.preventDefault();

    if (level) {
      if (
        level !== "Basic" &&
        level !== "Intermediate" &&
        level !== "SpeakingRoom" &&
        level !== "Advanced"
      ) {
        return alert("Please recheck your URL");
      }
    }

    const result = await ratingApi.request(formData, language, level);
    if (!result.ok) {
      if (result.data) setError(result.data.error);
      else {
        setError("An unexpected error occurred.");
        console.log(result);
      }
      return;
    }

    setTrainers(result.data.data);
  };

  return (
    <>
      <PublicLayout>
        <Container
          maxWidth="sm"
          component="main"
          className={classes.heroContent}
        >
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Find your ratings
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="textSecondary"
            component="p"
          >
            Please choose the appropriate criteria below to find the trainer's
            global ratings of English Partner.
          </Typography>
        </Container>
        {/* End hero unit */}
        <Container>
          <Card style={{ marginBottom: "10px" }} xs={12}>
            <CardContent xs={12}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3} align="center" justify="center">
                  <Grid item xs={12} sm={5}>
                    <TextField
                      id="from"
                      label="From Date"
                      type="date"
                      name="fromDate"
                      onChange={handleChange}
                      value={fromDate}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      id="to"
                      label="To Date"
                      type="date"
                      name="toDate"
                      value={toDate}
                      onChange={handleChange}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      fullWidth
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>

          {error && <Alert severity="danger">{error}</Alert>}

          {ratingApi.loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : ratingApi.data.length <= 0 ? (
            <Typography variant="h2" sm={12} align="center">
              No rows found
            </Typography>
          ) : (
            <Grid item padding={3}>
              <Card xs={12}>
                <div style={{ height: 600, width: "100%" }}>
                  <DataGrid
                    rows={trainers}
                    columns={columns}
                    getRowId={(row) => row._id}
                    pageSize={50}
                    checkboxSelection
                  />
                </div>
              </Card>
            </Grid>
          )}
        </Container>
      </PublicLayout>
    </>
  );
}

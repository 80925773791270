import React from "react";
import { Redirect, Route } from "react-router";
import useAuth from "../hooks/useAuth";

const PrivateRoute = ({ component: Component, title, ...rest }) => {
  const { user } = useAuth();

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      {user && <Route {...rest} render={(props) => <Component {...props} />} />}
    </>
  );
};

export default PrivateRoute;

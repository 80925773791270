import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import PublicLayout from "../../components/layouts/PublicLayout";
import BatchSelector from "../../components/common/BatchSelector";
import GroupSelector from "../../components/common/GroupSelector";
import { Alert, Rating } from "@material-ui/lab";
import {
  Button,
  Card,
  CardContent,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import admin from "../../api/admin";
import ratingApi from "../../api/rating";
import useApi from "../../hooks/useApi";
import { Redirect, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },

  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
}));

export default function AddSpotRatings() {
  const { trainerId } = useParams();

  const classes = useStyles();

  const getTrainer = useApi(admin.getTrainerInfo);
  const addRatingApi = useApi(ratingApi.addSpotRating);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [trainer, setTrainer] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [rating, setRating] = useState(null);

  const [success, setSuccess] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    loadTrainer();
  }, []);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 5000);
    }
  }, [error]);

  const loadTrainer = async () => {
    const result = await getTrainer.request(trainerId);
    if (!result.ok) {
      if (result.status === 404) setError("Invalid Trainer");
      if (result.data) setError(result.data.error);
      else {
        setError("An unexpected error occurred.");
        console.log(result);
      }
      return;
    }
    setTrainer(result.data.data);
  };

  const validatePhone = (enteredPhoneNumber) => {
    var pattern = new RegExp(/^[0-9\b]+$/);
    if (!pattern.test(enteredPhoneNumber)) {
      setError("Enter valid phone number");
    } else if (enteredPhoneNumber.length !== 10) {
      setError("Enter valid phone number");
    } else {
      return true;
    }
    return false;
  };

  const validateRatingInputs = () => {
    const phoneCheck = validatePhone(phone);
    if (phoneCheck) return true;
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess(false);
    setError(false);

    if (rating === null) {
      return setError("Please fill the ratings for trainers.");
    }

    let formData = {};
    formData.name = name;
    formData.phone = phone;
    formData.trainer = trainerId;
    formData.rating = rating;
    formData.remarks = remarks;

    const result = await addRatingApi.request(formData);

    if (!result.ok) {
      if (result.data) setError(result.data.error);
      else {
        setError("An unexpected error occurred.");
        console.log(result);
      }
      return;
    }

    if (result.data.success) setSuccess(true);
  };

//   Uncomment as and when needed
//   if (moment().date() === 30 || moment().date() === 31) {
//     return <Redirect to="/feedback/expired" />;
//   }

  return (
    <>
      <PublicLayout>
        {success ? (
          <Container
            maxWidth="sm"
            component="main"
            className={classes.heroContent}
          >
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Thank you for your feedback!
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              component="p"
            >
              Your feedback has been received.
            </Typography>
          </Container>
        ) : (
          <>
            <Container
              maxWidth="sm"
              component="main"
              className={classes.heroContent}
            >
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="textPrimary"
                gutterBottom
              >
                Your feedback is important to us!
              </Typography>
              <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                component="p"
              >
                Please fill the below details.
              </Typography>
            </Container>
            {/* End hero unit */}

            {getTrainer.loading ? (
              <Container
                align="center"
                justify="center"
                style={{ marginTop: "10px" }}
              >
                <CircularProgress size={26} />
              </Container>
            ) : !trainer ? (
              <Alert severity="error">
                Oops! Seems like the url that you have entered is incorrect.
                Please get the valid link from your trainer!
              </Alert>
            ) : (
              <form onSubmit={handleSubmit}>
                <Container maxWidth="sm">
                  {error && <Alert severity="info">{error}</Alert>}
                  <Grid
                    container
                    style={{ marginBottom: "10px", marginTop: "10px" }}
                    spacing={3}
                  >
                    <Grid item xs={12} sm={6}>
                      <Card>
                        <CardContent align="center" justify="center">
                          <TextField
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            id="Name"
                            label="Your name"
                            fullWidth
                            required
                            pattern="^[a-zA-Z]+(\s[a-zA-Z]+)?$"
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Card>
                        <CardContent align="center" justify="center">
                          <TextField
                            type="tel"
                            name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            id="phone"
                            label="Your mobile number"
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            fullWidth
                            min={10}
                            max={10}
                            required
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    style={{ marginBottom: "10px", marginTop: "10px" }}
                  >
                    <Grid item xs={12}>
                      <Card container>
                        <CardContent align="center" justify="center">
                          <Typography variant="h6">
                            Your feedback on our trainer
                          </Typography>
                          <Typography
                            style={{ marginBottom: "10px", marginTop: "15px" }}
                            variant="h3"
                          >
                            {trainer.name}
                          </Typography>
                          <Rating
                            name="rating"
                            value={rating}
                            onChange={(event, newValue) => setRating(newValue)}
                            required
                          />
                        </CardContent>

                        <CardContent align="center" justify="center">
                          <TextField
                            name="remarks"
                            label={`Feedback on ${trainer.name}`}
                            multiline
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                            placeholder={`Add your comments on ${trainer.name}`}
                            fullWidth
                          ></TextField>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>

                  {addRatingApi.loading ? (
                    <Container
                      align="center"
                      justify="center"
                      style={{ marginTop: "10px" }}
                    >
                      <CircularProgress size={26} />
                    </Container>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                      style={{ marginTop: "10px" }}
                    >
                      Submit
                    </Button>
                  )}

                  {/* {
                  <Container s>
                    <CircularProgress size={26} />
                  </Container>
                }
                <Button fullWidth variant="text" color="primary" type="submit">
                  Submit feedback
                </Button> */}
                </Container>
              </form>
            )}
          </>
        )}
      </PublicLayout>
    </>
  );
}
